@font-face {
    font-family: "Vazirmatn-Bold";
    src: local("Vazirmatn-Bold"),
        url("../fonts/Vazirmatn-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Vazirmatn";
    src: local("Vazirmatn-Regular"),
        url("../fonts/Vazirmatn-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "NokaTrial-Bold";
    src: local("NokaTrial-Bold"),
        url("../fonts/NokaTrial-Bold-BF63bccdec13b50.otf") format("truetype");
}

@font-face {
    font-family: "NokaTrial-SemiBold";
    src: local("NokaTrial-SemiBold"),
        url("../fonts/NokaTrial-Semibold-BF63bccdec16a7a.otf") format("truetype");
}


@font-face {
    font-family: "ReadexPro";
    src: local("ReadexPro-Bold"),
        url("../fonts/ReadexPro-Bold.ttf") format("truetype");
}

.miniCarIcon {
    transition: all .2s ease-in-out
}
.miniCarIcon:hover { transform: scale(1.1); }